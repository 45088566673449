const AppHeaderFullStoryScript = () => {
  return (
    <script
      id="fullStoryCode"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: 'window[\'_fs_host\'] = \'fullstory.com\';\n' +
          'window[\'_fs_script\'] = \'edge.fullstory.com/s/fs.js\';\n' +
          'window[\'_fs_org\'] = \'o-1P0XKA-na1\';\n' +
          'window[\'_fs_namespace\'] = \'FS\';\n' +
          '!function(m,n,e,t,l,o,g,y){var s,f,a=function(h){\n' +
          'return!(h in m)||(m.console&&m.console.log&&m.console.log(\'FullStory namespace conflict. Please set window["_fs_namespace"].\'),!1)}(e)\n' +
          ';function p(b){var h,d=[];function j(){h&&(d.forEach((function(b){var d;try{d=b[h[0]]&&b[h[0]](h[1])}catch(h){return void(b[3]&&b[3](h))}\n' +
          'd&&d.then?d.then(b[2],b[3]):b[2]&&b[2](d)})),d.length=0)}function r(b){return function(d){h||(h=[b,d],j())}}return b(r(0),r(1)),{\n' +
          'then:function(b,h){return p((function(r,i){d.push([b,h,r,i]),j()}))}}}a&&(g=m[e]=function(){var b=function(b,d,j,r){function i(i,c){\n' +
          'h(b,d,j,i,c,r)}r=r||2;var c,u=/Async$/;return u.test(b)?(b=b.replace(u,""),"function"==typeof Promise?new Promise(i):p(i)):h(b,d,j,c,c,r)}\n' +
          ';function h(h,d,j,r,i,c){return b._api?b._api(h,d,j,r,i,c):(b.q&&b.q.push([h,d,j,r,i,c]),null)}return b.q=[],b}(),y=function(b){function h(h){\n' +
          '"function"==typeof h[4]&&h[4](new Error(b))}var d=g.q;if(d){for(var j=0;j<d.length;j++)h(d[j]);d.length=0,d.push=h}},function(){\n' +
          '(o=n.createElement(t)).async=!0,o.crossOrigin="anonymous",o.src="https://"+l,o.onerror=function(){y("Error loading "+l)}\n' +
          ';var b=n.getElementsByTagName(t)[0];b&&b.parentNode?b.parentNode.insertBefore(o,b):n.head.appendChild(o)}(),function(){function b(){}\n' +
          'function h(b,h,d){g(b,h,d,1)}function d(b,d,j){h("setProperties",{type:b,properties:d},j)}function j(b,h){d("user",b,h)}function r(b,h,d){j({\n' +
          'uid:b},d),h&&j(h,d)}g.identify=r,g.setUserVars=j,g.identifyAccount=b,g.clearUserCookie=b,g.setVars=d,g.event=function(b,d,j){h("trackEvent",{\n' +
          'name:b,properties:d},j)},g.anonymize=function(){r(!1)},g.shutdown=function(){h("shutdown")},g.restart=function(){h("restart")},\n' +
          'g.log=function(b,d){h("log",{level:b,msg:d})},g.consent=function(b){h("setIdentity",{consent:!arguments.length||b})}}(),s="fetch",\n' +
          'f="XMLHttpRequest",g._w={},g._w[f]=m[f],g._w[s]=m[s],m[s]&&(m[s]=function(){return g._w[s].apply(this,arguments)}),g._v="2.0.0")\n' +
          '}(window,document,window._fs_namespace,"script",window._fs_script);',
      }}
    />
  );
};

export default AppHeaderFullStoryScript;

const AppHeaderGtagScript = () => {
  return (
    <>
      <script
        id="gtag"
        type="text/javascript"
        src="https://www.googletagmanager.com/gtag/js?id=G-Z034G2SXDC"
        async
      ></script>
      <script
        id="gtagCode"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: 'window.dataLayer = window.dataLayer || [];\n' +
            '  function gtag(){dataLayer.push(arguments);}\n' +
            '  gtag(\'js\', new Date());\n' +
            '\n' +
            '  gtag(\'config\', \'G-Z034G2SXDC\');',
        }}
      />
    </>
  );
};

export default AppHeaderGtagScript;

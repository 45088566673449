const AppBodyGtmFrame = () => {
  return (
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NFRFRF86"
              height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
    </noscript>
  );
};

export default AppBodyGtmFrame;

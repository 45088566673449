import { DATADOME_JS, DATADOME_TAGS } from '@/lib/constants';
import Script from 'next/script';

const AppBodyDatadome = () => {
  return (
    <>
      <Script strategy="lazyOnload" id="load-datadome">{`
        window.ddjskey = '${process.env.NEXT_PUBLIC_DATADOME_CLIENT_SIDE_KEY}'
        window.ddoptions = {
          endpoint: '${DATADOME_JS}'
        }
      `}</Script>
      <Script src={DATADOME_TAGS} strategy="lazyOnload" />
    </>
  );
};

export default AppBodyDatadome;

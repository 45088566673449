const AppHeaderGtmScript = () => {
  return (
    <script
      id="gtmCode"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html:
          "function font_faml_qtbyqygu(w,d,s,l,i){ var fnzl_zfxljt={}; var mtd = 'GET';w[l]=w[l]||[]; w[l].push({'gtm.start':new Date().getTime(), event:'gtm.js'}); var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:''; j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);}; font_faml_qtbyqygu(window,document,'script','dataLayer','GTM-NFRFRF86');",
      }}
    />
  );
};

export default AppHeaderGtmScript;

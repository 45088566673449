import React from 'react';
import { Spinner } from '../Spinner';
import type { UIComponentProps, UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';
import { getMimeTypeFromFilename } from '../utils/file';

export type ImageOnLoadingCompleteCallback = (result: { naturalWidth?: number; naturalHeight?: number }) => void;

export type ImageProps = UIThemableComponent<UIComponentProps<HTMLImageElement>, HTMLImageElement> & {
  src?: string | string[];
  alt?: string;
  spinner?: boolean;
  spinnerDelay?: number;
  fallback?: React.ReactNode;
  onLoadingComplete?: ImageOnLoadingCompleteCallback;
};

function ImageComp(
  {
    className,
    spinner,
    spinnerDelay = 500,
    fallback,
    src,
    alt,
    onLoadingComplete,
    classNames,
    ...otherProps
  }: ImageProps,
  forwardedRef: React.ForwardedRef<HTMLImageElement>
) {
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const loadingTimeout = React.useRef<NodeJS.Timeout>();
  const [image, setImage] = React.useState<string>();

  const [isSvg, setIsSvg] = React.useState<boolean>(false);

  const finalSrc: string | undefined = React.useMemo(() => {
    if (Array.isArray(src)) {
      return src[0];
    } else {
      return src;
    }
  }, [src]);

  React.useEffect(() => {
    (async () => {
      const finalSrc: string | undefined = Array.isArray(src) ? src[0] : src;

      if (!finalSrc) return false;
      const mimeType = await getMimeTypeFromFilename(finalSrc);

      if (mimeType === 'image/svg+xml') {
        (async () => {
          const res = await fetch(finalSrc as string);
          const rawImage = await res.text();
          setIsSvg(true);

          setImage(rawImage);
        })();
        return;
      }

      if (!spinner) return undefined;
      // set is loading to true after some delay to prevent the loading spinner from showing up a split second
      loadingTimeout.current = setTimeout(() => {
        setIsLoading(true);
      }, spinnerDelay);
      return () => clearTimeout(loadingTimeout.current);
    })();
  }, [spinnerDelay, loadingTimeout, spinner, finalSrc, isSvg, src]);

  const handleOnLoadingComplete: React.ReactEventHandler<HTMLImageElement> = React.useCallback(
    event => {
      setIsLoading(false);

      const imgEl = event.target as HTMLImageElement;

      onLoadingComplete?.({ naturalWidth: imgEl?.width, naturalHeight: imgEl?.height });

      if (!spinner) return;
      clearTimeout(loadingTimeout.current);
    },
    [spinner, onLoadingComplete]
  );

  if (!finalSrc) {
    return (
      <div className={cn('fallback', className)}>
        {fallback || (
          <>
            image
            <br />
            coming
            <br />
            soon
          </>
        )}
      </div>
    );
  }

  let content;

  if (isLoading) {
    if (spinner) content = <Spinner />;
  } else {
    if (isSvg) {
      if (!image && spinner) {
        content = spinner;
      } else {
        content = (
          <div
            dangerouslySetInnerHTML={{ __html: image || '' }}
            className={cn(className, '[&>svg]:h-full [&>svg]:w-auto')}
          />
        );
      }
    } else {
      content = (
        <img
          ref={forwardedRef}
          className={cn(className)}
          src={finalSrc}
          onLoad={handleOnLoadingComplete}
          {...otherProps}
          alt={alt}
        />
      );
    }
  }

  return <div className={classNames?.wrapper}>{content}</div>;
}

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ImageComp
) as React.ForwardRefExoticComponentExtended<ImageProps>;
